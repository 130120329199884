<script lang="ts">
  import { onMount } from 'svelte';
  import type { TimelineFeed } from '../firebase/common/feed';
  import Layout from '../widgets/Layout.svelte';
  import { getTimeline } from '../firebase/api/feed';
  import Paper, { Content } from '@smui/paper';
  import CircularProgress from '@smui/circular-progress';
  import FeedAbstractCard from '../widgets/FeedAbstractCard.svelte';
  import { fly } from 'svelte/transition';
  import type { JSONable } from '../firebase/common/jsonable';
  import CheckScrollLasts from '../widgets/CheckScrollLasts.svelte';
  import { authorized } from '../firebase/auth';

  let retrieveFeeds: Promise<void> | null;
  let currentCID: string | null;
  let feeds: JSONable<TimelineFeed>[] = [];
  let page = 1;
  let count = 0;

  let loggedIn: boolean = false;

  onMount(async () => {
    loggedIn = (await authorized) !== null;
    retrieveFeeds = reset();
  });

  const reset = async () => {
    page = 1;
    retrieveFeeds = null;
    if (currentCID) {
      const timeline = await getTimeline(1, currentCID);
      feeds = timeline.feeds;
      count = timeline.count;
      return;
    }
    feeds = [];
    count = 0;
    page = 1;
  };
</script>

<svelte:head>
  <title>AIFAN - 新たな創作の形を応援する支援サイト</title>
</svelte:head>

<Layout bind:currentCID>
  <div class="card">
    <h2 class="title">支援中タイムライン</h2>
    <article>
      <Paper variant="unelevated" style="position: relative" class="sp-paper">
        <Content>
          <div class="feeds" class:wide-view={!loggedIn}>
            {#if feeds.length === 0 && retrieveFeeds === null}
              <div class="no-feeds">
                <p>まだ投稿はありません</p>
              </div>
            {:else}
              {#each feeds as { channel, feed, requiredPlan, currentPlan }}
                <FeedAbstractCard
                  owner={channel.id === currentCID}
                  {channel}
                  feed={{ ...feed, published_at: new Date(feed.published_at) }}
                  {requiredPlan}
                  {currentPlan}
                  {currentCID}
                  narrow
                  showChannelInfo
                />
              {/each}
            {/if}
          </div>
          {#await retrieveFeeds}
            <div class="loading" transition:fly={{ duration: 250, y: -50 }}>
              <div class="loading-sizer">
                <CircularProgress indeterminate style="width: 4rem; height: 4rem" />
              </div>
            </div>
          {/await}
          <CheckScrollLasts
            onVisible={() => {
              retrieveFeeds = (async () => {
                if (currentCID === null) {
                  return;
                }

                const timeline = await getTimeline(page + 1, currentCID);

                if (timeline.feeds.length === 0) {
                  return;
                }

                feeds = [...feeds, ...timeline.feeds];
                page++;

                retrieveFeeds = null;
              })();
            }}
          />
        </Content>
      </Paper>
    </article>
  </div>
</Layout>

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;
  .card {
    width: var(--body-width);
    overflow: hidden;
    background-color: #fff;
    padding: 0.5rem;
    h2 {
      margin: 0;
      padding: 1rem 1rem 0;
      margin-bottom: 1rem;
    }
    :global(.smui-paper) {
      padding: 0;
    }
  }

  .loading {
    position: relative;

    > .loading-sizer {
      margin: auto;
      width: fit-content;
    }
  }

  .feeds {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    &.wide-view {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: #{$tb-width}) {
    .feeds {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 0.5rem;
      width: 100%;
      padding: 0;
      > :global(.card) {
        width: 100%;
      }

      &.wide-view {
        grid-template-columns: 1fr;
      }
    }
    .title {
      padding-left: 1rem;
    }
  }
</style>
